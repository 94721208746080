<template>
  <div class="component-container">
    <div class="content-container">
      <div class="account-header">
        <el-button type="" @click="queryPage(true)">刷 新</el-button>
        <el-button type="primary" @click="handleAdd">新 增</el-button>
      </div>
      <div class="account-table">
        <el-table :data="list" style="width: 100%" size="medium">
          <el-table-column prop="name" label="名称"> </el-table-column>
          <el-table-column prop="agency" label="银行或机构">
            <template slot-scope="{ row }">
              <div :style="getAgencyStyle(row)" class="agency-tag">
                {{ getAgencyName(row) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="account" label="帐号">
            <template slot-scope="{ row }">
              <div v-if="row.account && row.account.length === 11">
                {{
                  row.account.slice(0, 3) +
                  " " +
                  row.account.slice(3, 7) +
                  " " +
                  row.account.slice(7)
                }}
              </div>

              <div v-else-if="row.account && row.account.length === 16">
                {{
                  row.account.slice(0, 4) +
                  " " +
                  row.account.slice(4, 8) +
                  " " +
                  row.account.slice(8, 12) +
                  " " +
                  row.account.slice(12)
                }}
              </div>

              <div v-else-if="row.account && row.account.length === 19">
                {{
                  row.account.slice(0, 4) +
                  " " +
                  row.account.slice(4, 8) +
                  " " +
                  row.account.slice(8, 12) +
                  " " +
                  row.account.slice(12, 16) +
                  " " +
                  row.account.slice(16)
                }}
              </div>

              <div v-else>
                {{ row.account }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="balance" label="余额"> </el-table-column>
          <el-table-column prop="status" label="状态">
            <template slot-scope="{ row }">
              <el-tag v-if="row.status === 1" type="success">使用中</el-tag>
              <el-tag v-if="row.status === 0" type="info">已停用</el-tag>
            </template>
          </el-table-column>

          <el-table-column label="操作">
            <template slot-scope="{ row }">
              <el-button type="primary" size="small" @click="handleEdit(row)">
                编 辑
              </el-button>
              <el-button type="danger" size="small" @click="handleDelete(row)">
                删 除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="pagination.total"
          :page-size="pagination.size"
          :current-page.sync="pagination.page"
          @current-change="queryPage()"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="500px"
      :before-close="handleBeforeClose"
      :close-on-click-modal="false"
      class="account-dialog"
    >
      <el-form
        ref="account-form"
        :model="accountForm"
        label-width="80px"
        :rules="rules"
        class="account-form"
      >
        <el-form-item label="账户名称" prop="name">
          <el-input v-model="accountForm.name"></el-input>
        </el-form-item>
        <el-form-item label="账户机构" prop="agency">
          <el-select v-model="accountForm.agency" placeholder="请选择账户机构">
            <el-option
              v-for="(ag, agIndex) in agencyList"
              :key="agIndex"
              :label="ag.name"
              :value="ag.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账户账号" prop="account">
          <el-input
            v-model="accountForm.account"
            placeholder="请选择账户账号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="额度" prop="balance">
          <el-input v-model="accountForm.balance"></el-input>
        </el-form-item>
        <el-form-item label="是否启用">
          <el-switch
            v-model="accountForm.status"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSave" :loading="saveLoading">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { agencyList } from "@/utils";
import Api from "@/api";
export default {
  name: "Account",
  data() {
    return {
      agencyList,
      list: [
        // {
        //   name: "生活卡",
        //   agency: "icbc",
        //   account: "6212777733330000000",
        //   balance: 234,
        //   status: 1,
        // }
      ],

      pagination: {
        total: 0,
        size: 10,
        page: 1,
      },

      dialogVisible: false,
      dialogTitle: "",
      accountForm: {
        id: "",
        name: "",
        agency: "",
        account: "",
        balance: "",
        status: 1,
      },
      rules: {
        name: [{ required: true, message: "请输入账户名", trigger: "blur" }],
        agency: [{ required: true, message: "请选择机构名", trigger: "change" }],
        balance: [{ required: true, message: "请输入额度", trigger: "blur" }],
      },
      saveLoading: false,
    };
  },
  mounted() {
    this.queryPage();
  },
  methods: {
    /**
     * 页面数据请求
     * @param {boolean} isClickRefresh 是否是点击了刷新的数据请求（如果是要弹消息）
     */
    async queryPage(isClickRefresh = false) {
      const { page, size } = this.pagination;

      const res = await Api.AccountApi.query({
        page,
        size,
      });
      if (res && res.code === 0) {
        const { data } = res;
        const { records, total } = data;
        this.list = records;
        this.pagination.total = total;
        if (isClickRefresh) {
          this.$message.success("刷新成功！");
        }
      } else {
        this.$message.error("列表请求失败");
      }
    },
    /**
     * 打开新增对话框
     */
    handleAdd() {
      this.dialogVisible = true;
      this.dialogTitle = "新增账户";
    },
    /**
     * 编辑行数据，打开对话框
     * @param {object} row 行数据
     */
    handleEdit(row) {
      this.dialogVisible = true;
      this.dialogTitle = "编辑账户";

      const { _id: id, name, agency, account, balance, status } = row;

      this.accountForm.id = id;
      this.accountForm.name = name;
      this.accountForm.agency = agency;
      this.accountForm.account = account;
      this.accountForm.balance = balance;
      this.accountForm.status = status;
    },
    /**
     * 删除账户
     * @param {object} row 行数据
     */
    handleDelete(row) {
      const { _id } = row;
      this.$confirm("是否要删除这一账户？", "提示", {
        confirmButtonText: "确定，删除账户",
        cancelButtonText: "取消，保留账户",
      })
        .then(async () => {
          const res = await Api.AccountApi.remove(_id);
          if (res && res.code === 0) {
            const { data } = res;
            if (data === true) {
              this.$message.success("删除成功");
            }
            this.queryPage();
          } else {
            this.$message.error("删除失败");
          }
        })
        .catch(() => {
          this.$message.info("取消了删除账户");
        });
    },
    /**
     * 关闭对话框前的回调
     */
    handleBeforeClose() {
      this.handleClose();
    },
    /**
     * 关闭对话框
     */
    handleClose() {
      this.$refs["account-form"] && this.$refs["account-form"].resetFields();
      this.dialogVisible = false;
      ({ accountForm: this.accountForm, dialogTitle: this.dialogTitle } =
        this.$options.data());
    },
    /**
     * 保存对话框
     */
    handleSave() {
      this.$refs["account-form"].validate(async (valid) => {
        if (!valid) {
          return;
        }
        this.saveLoading = true;
        if (this.accountForm.id) {
          // 编辑
          const res = await Api.AccountApi.update(this.accountForm).finally(
            () => {
              this.saveLoading = false;
            }
          );
          if (res && res.code === 0) {
            this.$message.success("编辑成功");
            this.handleClose();
            this.queryPage();
            ({ accountForm: this.accountForm, dialogTitle: this.dialogTitle } =
              this.$options.data());
          } else {
            this.$message.error("编辑失败");
          }
        } else {
          // 新增
          const res = await Api.AccountApi.add(this.accountForm).finally(() => {
            this.saveLoading = false;
          });
          if (res && res.code === 0) {
            this.$message.success("新增成功");
            this.handleClose();
            this.queryPage();
            ({ accountForm: this.accountForm, dialogTitle: this.dialogTitle } =
              this.$options.data());
          } else {
            this.$message.error("新增失败");
          }
        }
      });
    },
    /**
     * 获取银行或机构的名字
     * @param {object} row 行数据
     * @description 根据code（机构代码）去写死的表里获取
     * @returns 返回机构名称
     */
    getAgencyName(row) {
      const { agency } = row;
      const one = this.agencyList.find((item) => item.code === agency);

      let name = agency;
      if (one) {
        name = one.name;
      }
      return name;
    },
    /**
     * 获取银行或机构的颜色样式
     * @param {object} row 行数据
     * @description 根据code（机构代码）去写死的表里获取
     * @returns 返回机构颜色style，其中包含了背景色和边框色
     */
    getAgencyStyle(row) {
      const { agency } = row;
      const one = this.agencyList.find((item) => item.code === agency);
      let color = "red";
      if (one) {
        color = one.color;
      }

      const style = `border: 1px solid ${color};color:${color};background-color:${color}11`;
      return style;
    },
  },
};
</script>

<style lang="scss" scoped>
.account-header {
  padding: 27px 27px 0px 27px;
  display: flex;
  justify-content: flex-end;
}
.account-table {
  padding: 27px 27px 0px 27px;
  ::v-deep .el-table__header-wrapper {
    .el-table__cell {
      background-color: rgb(246, 246, 246);
      border-bottom: 1px solid rgb(221, 221, 221);
      color: black;
      font-weight: normal;
    }
  }
  .agency-tag {
    width: fit-content;
    padding: 3.5px 6px;
    border-radius: 6px;
  }
}
.pagination {
  padding: 27px 27px 27px 27px;
  bottom: 0px;
}
.account-dialog {
  .account-form {
    width: 400px;
    margin: 0px auto;
  }
}
</style>
